import {
  List,
  Table,
  useTable,
  Space,
  EditButton,
  useDrawerForm,
  CreateButton,
  useModal,
  RefreshButton,
  Tag,
  Button
} from '@pankod/refine-antd'
import { CrudFilters, GetOneResponse, HttpError, useTranslate } from '@pankod/refine-core'
import { ButtonLogicDelete } from 'components/DeleteModal/buttonLogicDelete'
import { DeleteModal } from 'components/DeleteModal/modal'
import { SupplementActions } from 'components/supplement/actions'
import { SendEmailButton } from 'components/supplement/send_email_button'
import { definitions, Order, Supplement } from 'interfaces'
import { useState, useEffect } from 'react'
import { capitalizeFirstLetter } from 'utility/capitalizeFirstLetter'
import { getColorLabel } from 'utility/colorLabel'
import { currency } from 'utility/currency'
import { QueryObserverResult } from '@tanstack/react-query'
import { ADMITTED_STATUS, SUPPLEMENT_PREDEFINED_OPTIONS, viewTicket, supabaseClient, ORDER_STATUS_ID_PENDING_PAYMENT, API_URL, DEFAULT_DATE_TIME_FORMAT } from 'utility'
import { ProcessModal } from 'pages/shipments/processModal'
import moment from 'moment'
import { AdviceModal } from 'components/AdviceModal/AdviceModal'
// import { GroupedSupplementActions } from 'components/supplement/groupedSupplementForm'

type SupplementsProps = {
  order: definitions['Order'] | undefined;
  orderQuery: QueryObserverResult<GetOneResponse<Order>, unknown>
  setSupQuery?: any
  refetchRef?: any
};

export const Supplements: React.FC<SupplementsProps> = ({ order, orderQuery, setSupQuery, refetchRef }) => {
  const { modalProps, show, close } = useModal()
  const { modalProps: modalPropsAdvice, show: showAdvice, close: closeAdvice } = useModal()
  const t = useTranslate()

  // const [allSupplements, setAllSupplements] = useState<any>([])
  const [id, setId] = useState(0)
  const { modalProps: modalPropsProcess, show: showProcess, close: closeProcess } = useModal()
  const [loading, setLoading] = useState(false)
  const [errorSaving, setErrorSaving] = useState<any>(null)
  const [adviseText, setAdviseText] = useState<string>('')

  const permFilters = [
    {
      field: 'order_id',
      operator: 'eq',
      value: order?.id
    },
    {
      field: 'deleted',
      operator: 'eq',
      value: false
    }
  ] as CrudFilters

  const { tableProps, tableQueryResult } = useTable<definitions['supplements'], HttpError>({
    resource: 'supplements',
    initialSorter: [
      {
        field: 'volumetric',
        order: 'desc'
      },
      {
        field: 'id',
        order: 'desc'
      }
    ],
    permanentFilter: permFilters,
    initialPageSize: 4,
    queryOptions: {
      enabled: order !== undefined
    },
    syncWithLocation: false,
    metaData: {
      select: '*, status_id(*)'
    }
  })

  const getAllSupplements = async () => {
    const { data: suplements } = await supabaseClient.from('supplements')
      .select('*')
      .eq('order_id', order?.id)
      .eq('deleted', false)
    // setAllSupplements(suplements)
    return suplements
  }

  useEffect(() => {
    if (tableQueryResult.status === 'success') {
      setSupQuery(tableQueryResult)
    }
  }, [tableQueryResult])

  const handleRefresh = () => {
    tableQueryResult.refetch()
  }

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    close: createClose,
    show: createShow
  } = useDrawerForm<definitions['supplements']>({
    action: 'create',
    resource: 'supplements',
    redirect: false
  })

  // const {
  //   drawerProps: createGroupedDrawerProps,
  //   formProps: createGroupedFormProps,
  //   close: createGroupedClose,
  //   show: createGroupedShow
  // } = useDrawerForm<definitions['supplements']>({
  //   action: 'create',
  //   resource: 'supplements',
  //   redirect: false
  // })

  const {
    formProps: editFormProps,
    drawerProps: editDrawerProps,
    show: editShow,
    close: editClose
  } = useDrawerForm<definitions['supplements']>({
    action: 'edit',
    resource: 'supplements',
    redirect: false
  })

  createFormProps.initialValues = {
    order_id: order?.id
  }

  editFormProps.initialValues = {
    ...editFormProps.initialValues,
    order_id: order?.id
  }

  const getConditionalClassName = (record: definitions['supplements']) => {
    if (record.parent) {
      return 'supplement-unified'
    }
    return ''
  }

  const createGroupedSupplement = async () => {
    setLoading(true)
    // get all suplements availables and create a new unified supplement
    const allSup = await getAllSupplements()

    // not sent that have pending status and not is a refund type
    const availableSup = allSup?.filter((x: Supplement) => x.description !== 'unified' && x.parent === null && x.email_sent_at === null).filter((x: Supplement) => !x.volumetric) || []

    let supplementInserted: Supplement | undefined

    if (availableSup.length === 1) {
      supplementInserted = availableSup[0]
    } else if (availableSup.length > 1) {
      const finalSupplement = availableSup?.map((sup: Supplement) => sup.supplement || 0).reduce((acc, current) => acc + current, 0) || 0

      const state = {
        order_id: order?.id,
        volumetric: false,
        supplement: finalSupplement,
        description: 'unified',
        status_id: (finalSupplement > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS
      }
      // Save the supplement and change the other suplements, if is minor to 0, create a refund too
      const { data, error } = await supabaseClient.from('supplements')
        .insert(state)
        .single()
      if (error) {
        setErrorSaving(error)
      }

      if (data && data.id) {
        supplementInserted = data
        if (finalSupplement > 0) {
          const { error: supError } = await supabaseClient.from('supplements')
            .update({
              parent: data?.id,
              read_by_user: true // to remove a duplicated notification
            })
            .in('id', availableSup.map((x: any) => x.id))

          if (supError) {
            setErrorSaving(supError)
            return
          }
        } else {
          const { error: supError } = await supabaseClient.from('supplements')
            .update({
              parent: data?.id,
              read_by_user: true, // to remove a duplicated notification
              status_id: ADMITTED_STATUS
            })
            .in('id', availableSup.map((x: any) => x.id))

          if (supError) {
            setErrorSaving(supError)
            return
          }
        }
        // create the refund too if we have refunds produced.
        const { data: refundsData, error: refundsError } = await supabaseClient.from('refunds')
          .select('id, description, amount')
          .eq('order_id', order?.id)
          .eq('deleted', false)
        if (refundsError) {
          setErrorSaving(refundsError)
          return
        }
        const refundsToRemove = refundsData?.filter((x: any) => x.description === 'refundStore' || x.description === 'refundTransport' || availableSup.find((y: any) => y.reason === x.description))
        if (finalSupplement >= 0) {
          await supabaseClient.from('refunds').update({
            deleted: true,
            deleted_by: supabaseClient.auth.user()?.email || null
          }).in('id', refundsToRemove.map((x: any) => x.id))
        } else {
          if (refundsToRemove && refundsToRemove.length > 0) {
            await supabaseClient.from('refunds').update({
              deleted: true,
              deleted_by: supabaseClient.auth.user()?.email || null
            }).in('id', refundsToRemove.map((x: any) => x.id))
          }
          await supabaseClient.from('refunds').insert({
            description: 'unified',
            amount: Math.abs(finalSupplement),
            status_id: ORDER_STATUS_ID_PENDING_PAYMENT,
            order_id: order?.id,
            deleted: false,
            type: 'client'
          })
        }
      }
    }

    // Finally sent supplement email
    if (errorSaving === null) {
      await sendUnifiedSuplementEmail(supplementInserted)
    }

    setLoading(false)
    handleRefresh()
    refetchRef()
  }

  const sendUnifiedSuplementEmail = async (supplement: Supplement | undefined) => {
    if (supplement && supplement.email_sent_at === null) {
      // Prepare request
      const options: RequestInit = {
        method: 'post',
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          type: 'INSERT',
          table: 'supplements',
          record: supplement,
          fromButton: true
        })
      }

      // Request to send email
      const response = await fetch(`${API_URL}/email`, options)

      if (response.ok) {
        const timeMoment = moment()

        await supabaseClient
          .from('supplements')
          .update({ email_sent_at: timeMoment })
          .eq('id', supplement.id)

        supplement.email_sent_at = timeMoment.format(DEFAULT_DATE_TIME_FORMAT)
      }
    }
  }

  return (
    <>
      <List
        title={t('order.fields.supplements')}
        breadcrumb={false}
        pageHeaderProps={{
          extra: (
            <>
              {/* <CreateButton onClick={() => createGroupedShow()}> */}
              <CreateButton loading={loading} onClick={() => createGroupedSupplement()}>
                {t('order.actions.addUnifiedSupplement')}
              </CreateButton>
              <CreateButton onClick={() => createShow()}>
                {t('order.actions.addSupplement')}
              </CreateButton>
              <RefreshButton onClick={handleRefresh} />
            </>
          )
        }}
      >
        <Table {...tableProps}
          rowKey="id"
          rowClassName={(record) => getConditionalClassName(record)}
        >
          <Table.Column
            key="weigth"
            dataIndex="weigth"
            title={t('supplement.fields.weigth')}
            align='center'
            sorter
          />
          <Table.Column
            key="length"
            dataIndex="length"
            title={t('supplement.fields.length')}
            align='center'
            sorter
          />
          <Table.Column
            key="heigth"
            dataIndex="heigth"
            title={t('supplement.fields.heigth')}
            align='center'
            sorter
          />
          <Table.Column
            key="depth"
            dataIndex="depth"
            title={t('supplement.fields.depth')}
            align='center'
            sorter
          />
          <Table.Column
            key="volume"
            dataIndex="volume"
            title={t('supplement.fields.volume')}
            align='center'
            sorter
          />
          <Table.Column
            key="billableVolume"
            dataIndex="billable_volume"
            title={t('supplement.fields.billableVolume')}
            align='center'
            sorter
          />
          <Table.Column
            key="weigthExcess"
            dataIndex="weigth_excess"
            title={t('supplement.fields.weigthExcess')}
            align='center'
            sorter
          />
          <Table.Column
            key="description"
            dataIndex="description"
            title={t('supplement.fields.description')}
            align='center'
            sorter
            render={(value) => SUPPLEMENT_PREDEFINED_OPTIONS.includes(value) ? t(`supplement.descriptions.${value}`) : value }
          />
          <Table.Column
            key="reason"
            dataIndex="reason"
            title={t('supplement.fields.reason')}
            align='center'
            sorter
          />
          <Table.Column
            key="supplement"
            dataIndex="supplement"
            title={t('supplement.fields.supplement')}
            render={(value) => currency(value)}
            align='center'
            sorter
          />
          <Table.Column
            dataIndex={['status_id', 'name']}
            align="center"
            title={t('supplement.fields.status')}
            render={(value) => (
              <Tag color={getColorLabel(value)}>
                {capitalizeFirstLetter(value)}
              </Tag>
            )}
            sorter
          />
          <Table.Column<definitions['supplements']>
            key="sendEmail"
            dataIndex="sendEmail"
            title={t('supplement.fields.sendEmail')}
            render={(_, record) => <SendEmailButton supplement={record} refreshTable={handleRefresh} isSupplement={true} isEstimatedCost={false} />}
            align='center'
          />
          <Table.Column<definitions['supplements']>
            title={t('table.actions')}
            dataIndex="actions"
            align='center'
            render={(_, record: any) => (
              <Space>
                <EditButton
                  disabled={record.parent !== null || record.description === 'unified'}
                  onClick={() => editShow(record.id)}
                  hideText
                />
                {record.volumetric && record.status_id?.id === ADMITTED_STATUS && <Space style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    onClick={() => {
                      console.log(order)
                      if (order?.shipments.find((x) => x.tracking_id)) {
                        setAdviseText(t('supplement.advise.duplicatedPrepare'))
                        showAdvice()
                      } else if (order?.shipping_method.name.toLowerCase().includes('express')) {
                        setAdviseText(t('supplement.advise.dangerousWare'))
                        showAdvice()
                      } else {
                        showProcess()
                      }
                    }}
                  >
                    {t('shipments.actions.prepare')}
                  </Button>
                  <Button onClick={() => viewTicket(undefined, order?.id)}>
                    {t('shipments.actions.showTicket')}
                  </Button>
                </Space>}
                {(record.parent === null) && <ButtonLogicDelete click={() => {
                  show()
                  setId(record.id)
                }}
                />}
                <DeleteModal
                  modalProps={modalProps}
                  close={close}
                  id={id}
                  orderId={record.order_id}
                  entity='supplements'
                  tableQueryResult={tableQueryResult}
                />
              </Space>
            )}
          />
        </Table>
        {order && <>
          {/* <GroupedSupplementActions
            drawerProps={createGroupedDrawerProps}
            formProps={createGroupedFormProps}
            closeDrawer={createGroupedClose}
            getAllSupplements={getAllSupplements}
            tableProps={tableProps.dataSource}
            allSupplements={allSupplements?.filter((x: Supplement) => x.description !== 'unified' && x.parent === null) || []}
            tableQueryResult={tableQueryResult}
            orderId={order.id}
            orderQuery={orderQuery}
          /> */}
          <SupplementActions
            drawerProps={createDrawerProps}
            formProps={createFormProps}
            closeDrawer={createClose}
            tableQueryResult={tableQueryResult}
            orderId={order.id}
            shippingMethod={order.shipping_method}
            island={order.shipping_address.island}
            orderQuery={orderQuery}
            packageNumber={order.package_number}
            order={order}
          />
          <SupplementActions
            edit={true}
            drawerProps={editDrawerProps}
            formProps={editFormProps}
            closeDrawer={editClose}
            tableQueryResult={tableQueryResult}
            orderId={order.id}
            shippingMethod={order.shipping_method}
            island={order.shipping_address.island}
            orderQuery={orderQuery}
            packageNumber={order.package_number}
            order={order}
          />
          <ProcessModal
            close={closeProcess}
            modalProps={modalPropsProcess}
            tableQuery={orderQuery}
            orderId={order?.id}
          />
          <AdviceModal
            modalProps={modalPropsAdvice}
            close={closeAdvice}
            show={showAdvice}
            infoText={adviseText}
            okFunction={() => showProcess()}
          />
        </>}
      </List>
    </>
  )
}
