import { NestedKeys, ValidOperators } from 'types/utilities'
import { ACEPTED_STATUS_TEXT, ADMITTED_STATUS, ADMITTED_STATUS_TEXT, ADVERTISEMENT_STATUS_TEXT, API_URL, CANCELLED_STATUS, CANCELLED_STATUS_TEXT, CANCEL_STATUS_TEXT, DECREASE_RETURN_STATUS_TEXT, DELIVERED_STATUS, DELIVERED_STATUS_TEXT, GENDER_MAN, GENDER_NO_GENDER, GENDER_OTHER, GENDER_WOMAN, INTERNAL_SPENDING_RETURN_STATUS_TEXT, NO_STOCK_STATUS_TEXT, ORDER_STATUS_ID_ON_HOLD, ORDER_STATUS_ID_PENDING_PAYMENT, ORDER_STATUS_ID_REQUESTED, PARCIAL_RETURN_STATUS, PARCIAL_RETURN_STATUS_TEXT, PENALIZATION_STATUS, PENALIZATION_STATUS_TEXT, PENDING_PAYMENT_STATUS_TEXT, PREPARED_STATUS, PREPARED_STATUS_TEXT, PROCESSED_STATUS, PROCESS_STATUS_TEXT, REFERRAL_BENEFIT_STATUS_ACCEPTED, REFERRAL_BENEFIT_STATUS_MODIFIED, REFERRAL_BENEFIT_STATUS_PENDING, REFUND_STATUS, REFUND_STATUS_TEXT, REQUEST_STATUS_TEXT, RETURN_STATUS, RETURN_STATUS_TEXT, SENT_BUDGE_STATUS_TEXT, SHIPPED_STATUS, SHIPPED_STATUS_TEXT, SITES_DOMAINS_EXCLUDES, SUPPLEMENT_STATUS, SUPPLEMENT_STATUS_TEXT } from './constants'
import { supabaseClient } from './supabaseClient'
import { formatDateTime } from './formatDateTime'
import { User } from 'interfaces'
import { getOrdersWithWalletAmountUsed, getReferredBenefits, getReferrerBenefits } from './supabaseApi'
import { t } from 'i18next'

const regex = /(?<=https:\/\/)[^/]+/

export function MapStatusIDToText (id: number) {
  switch (id) {
    case 0:
      return PENDING_PAYMENT_STATUS_TEXT
    case 1:
      return REQUEST_STATUS_TEXT
    case 2:
      return PROCESS_STATUS_TEXT
    case 3:
      return CANCEL_STATUS_TEXT
    case 4:
      return RETURN_STATUS_TEXT
    case 5:
      return SUPPLEMENT_STATUS_TEXT
    case 6:
      return ADMITTED_STATUS_TEXT
    case 7:
      return NO_STOCK_STATUS_TEXT
    case 8:
      return PREPARED_STATUS_TEXT
    case 9:
      return SHIPPED_STATUS_TEXT
    case 10:
      return DELIVERED_STATUS_TEXT
    case 11:
      return PENALIZATION_STATUS_TEXT
    case 12:
      return REFUND_STATUS_TEXT
    case 13:
      return PARCIAL_RETURN_STATUS_TEXT
    case 14:
      return DECREASE_RETURN_STATUS_TEXT
    case 15:
      return INTERNAL_SPENDING_RETURN_STATUS_TEXT
    default:
      return PENDING_PAYMENT_STATUS_TEXT
  }
}

export function MapEstimatedCostsStatusIDToText (id: number | undefined) {
  switch (id) {
    case 1:
      return ADVERTISEMENT_STATUS_TEXT
    case 2:
      return SENT_BUDGE_STATUS_TEXT
    case 3:
      return ACEPTED_STATUS_TEXT
    case 4:
      return CANCELLED_STATUS_TEXT
    default:
      return ''
  }
}

export const viewTicket = async (record: any, orderId?: number, isCompanyOrder?: boolean) => {
  // Old version
  // let ticketName = null
  // let resource = 'shipments'
  // let select = 'order_id, ticket_name'
  // let filter = 'order_id'
  // if (isCompanyOrder) {
  //   resource = 'company_order'
  //   select = 'id, ticket_name'
  //   filter = 'id'
  // }
  // if (!record) {
  //   const { data: shipmentData } = await supabaseClient.from(`${resource}`)
  //     .select(`${select}`)
  //     .eq(`${filter}`, orderId)

  //   if (shipmentData === null) return
  //   if (shipmentData.length === 0) return
  //   ticketName = shipmentData[0].ticket_name
  // }
  // // Get ticket public url
  // const { data } = await supabaseClient
  //   .storage
  //   .from('tickets')
  //   .getPublicUrl(`${record?.ticket_name || ticketName}?${Date.now().toLocaleString()}`)

  // const zplContent = await fetch(`${data?.publicURL}`).then(res => res.text())

  // // Prepare request
  // const options: RequestInit = {
  //   method: 'post',
  //   headers: new Headers({
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //     Accept: 'application/pdf',
  //     Autorization: 'Bearer ed7248c7-5594-4e10-9e2e-c8b862cda089'
  //   }),
  //   body: zplContent
  // }

  // // Request to online converter
  // const image = await fetch(URL_ZPL_CONVERTER, options)

  // // Open blob
  // const blob = await image.blob()
  // window.open(URL.createObjectURL(blob))

  const id = (orderId !== undefined) ? orderId : record.order_id

  const options: RequestInit = {
    method: 'post'
  }

  // Request to online converter
  const image = await fetch(`${API_URL}/show-label/${id}/${isCompanyOrder || false}`, options)

  const res = await image.blob()

  const newBlob = new Blob([res], { type: 'application/pdf' })
  window.open(URL.createObjectURL(newBlob))
}

export const prepareUrl = (url: string) => {
  const site = regex.exec(url)
  if (site !== null && site.length > 0) {
    return site[0]
  } else {
    return url.split('/')[2]
  }
}

export const prepareItemUrl = (siteName: string) => {
  let result: any
  if (siteName) {
    if (siteName.includes('.')) {
      const siteSplitted = siteName.split('.')
      let sitesWithoutKnownDomains = siteSplitted.map((x: string) => {
        if (!SITES_DOMAINS_EXCLUDES.find((y: string) => y === x)) {
          return x
        } else {
          return null
        }
      })

      sitesWithoutKnownDomains = sitesWithoutKnownDomains.filter((x: string | null) => x !== null)
      result = (sitesWithoutKnownDomains.length === 1) ? sitesWithoutKnownDomains[0] : `${sitesWithoutKnownDomains[0]}.${sitesWithoutKnownDomains[1]}`
    } else {
      result = siteName
    }
  }
  return result || null
}

export type sorter = {
  field: string,
  ascending: boolean
}

export type filter = {
  field: string
  operator: string
  secondOp?: string
  value: any
}

export function sortAlphabetically (a: string, b: string) {
  if (a.toLowerCase() < b.toLowerCase()) return -1
  if (a.toLowerCase() > b.toLowerCase()) return 1
  return 0
}

export function getRandomString () {
  return (Math.random() + 1).toString(36).substring(2)
}

export type PostgrestFilterBuilder<T> = ReturnType<ReturnType<typeof supabaseClient.from<T>>['select']>
export type Filter<T = any> = {
  field: NestedKeys<T>
  operator: ValidOperators<PostgrestFilterBuilder<T>>
  secondOp?: ValidOperators<PostgrestFilterBuilder<T>>
  value: any
}

/**
 * Applies multiple filters to a Postgrest query. This function iterates over each filter object
 * provided in the `filters` array and applies them to the initial `query` object.
 * The `query` object is an instance of `PostgrestFilterBuilder`, which provides methods
 * for constructing queries in a fluent style.
 *
 * Note: As more conditions are required, this function should be modified to pass
 * the appropriate parameters to each filter function of the `PostgrestFilterBuilder` class
 * from `PostgrestFilterBuilder.ts` of `@supabase/postgrest-js` package.
 *
 * @param query   The initial query object of type `PostgrestFilterBuilder<T>`.
 * @param filters An array of filters to be applied. Each filter is an object that includes
 *                a field, an operator, a value, and optionally a second operator for specific
 *                operators like `not`.
 * @returns       The modified query object with all filters applied. If an operator
 *                does not correspond to a function on the query object, that filter is ignored
 *                thanks to the [ValidOperators<T>](../types/utilities.ts) utility type used on `Filter` type.
 */
export function queryFilters<T> (query: PostgrestFilterBuilder<T>, filters: Filter<T>[]) {
  filters.forEach(({ operator, field, value, secondOp }) => {
    if (operator === 'not') {
      query = query[operator](field as any, secondOp as any, value)
    } else if (operator === 'in') {
      query = query[operator](field as any, value)
    } else {
      query = query[operator](field as never, value as never, undefined) as PostgrestFilterBuilder<T>
    }
  })
  return query
}

export function getGender (gender?: number) {
  if (gender === GENDER_NO_GENDER) return 'order.fields.noGender'
  if (gender === GENDER_MAN) return 'order.fields.man'
  if (gender === GENDER_WOMAN) return 'order.fields.woman'
  if (gender === GENDER_OTHER) return 'order.fields.other'
  return 'common.no_specified'
}

export const getReturnDayLimit = (supDate: string, maxReturnDays: number) => {
  const maxDate = new Date(supDate)
  maxDate.setDate(maxDate.getDate() + maxReturnDays)
  return maxDate
}

export const getLastOrder = (orderList: any[], prev: number = -1) => {
  const list = orderList.filter((o: any) => o.status_id > 0)
  if (prev === -1) {
    return formatDateTime(list[0]?.created_at || undefined)
  } else if (prev === -2) {
    return formatDateTime(list[1]?.created_at || undefined)
  }
  return ''
}

export function calculateReferralAndWalletAmounts (benefits: BenefitsData[]) {
  const totalBenefits = benefits.filter((rb: { status: string }) => (rb.status === t(`users.referral_benefits.${REFERRAL_BENEFIT_STATUS_ACCEPTED}`) || rb.status === t(`users.referral_benefits.${REFERRAL_BENEFIT_STATUS_MODIFIED}`)))
    .reduce((acc: any, curr: any) => acc + (curr.benefit_amount), 0)

  // if (benefits[benefits.length - 1].referred) {
  //   totalBenefits += benefits[benefits.length - 1].benefit_amount
  // }

  const totalWalletAmountUsed = benefits.filter(o => o.order_id)
    .reduce((acc, curr) => acc + curr.benefit_amount, 0)

  const date = new Date(Date.now())
  const expiredBenefits = benefits.filter((rb) => rb.referred && rb.referred_expired_at && new Date(rb.referred_expired_at).toISOString() < date.toISOString())
    .reduce((acc, curr) => acc + curr.benefit_amount, 0)

  return { totalBenefits, totalWalletAmountUsed, expiredBenefits }
}

export type BenefitsData = {
  referred: boolean,
  key: string
  name: string
  status: string
  'referred_expired_at': string | null
  'benefit_amount': number
  'created_at': string
  'tag_color': string
  'order_id'?: number
}

export const STATUS_TAG_COLORS = {
  [REFERRAL_BENEFIT_STATUS_PENDING]: 'red',
  [REFERRAL_BENEFIT_STATUS_ACCEPTED]: 'green',
  [REFERRAL_BENEFIT_STATUS_MODIFIED]: 'gray',
  default: 'orange'
}

export async function calculateReferralAndWalletAsync (user: User) {
  return await calculateReferralAndWallet(user)
}

async function calculateReferralAndWallet (user: User) {
  const referralBenefits = await getReferredBenefits(user.id)
  const referrerBenefits = await getReferrerBenefits(user.id)
  const orders = await getOrdersWithWalletAmountUsed(user.id)

  const benefitsData: BenefitsData[] = []
  referralBenefits.forEach((rb, index) => {
    benefitsData.push({
      key: `benefit-referred-${rb.id}`,
      referred: true,
      name: (rb.referrer_user_id.id !== 0) ? rb.referred_user_id.name || '' : rb.referrer_user_id.name || '',
      status: t(`users.referral_benefits.${rb.status}`),
      benefit_amount: rb.benefit_amount,
      created_at: rb.created_at,
      referred_expired_at: rb.referred_expired_at || null,
      tag_color: STATUS_TAG_COLORS[rb.status as keyof typeof STATUS_TAG_COLORS],
      order_id: rb.order_id
    })
  })
  referrerBenefits.forEach((rb) => {
    benefitsData.push({
      key: `benefit-referrer-${rb.id}-${rb.referred_user_id.name}`,
      referred: false,
      name: rb.referred_user_id.name || '',
      status: t(`users.referral_benefits.${rb.status}`),
      benefit_amount: rb.benefit_amount,
      created_at: rb.created_at,
      referred_expired_at: null,
      tag_color: STATUS_TAG_COLORS[rb.status as keyof typeof STATUS_TAG_COLORS]
    })
  })
  orders.forEach((order) => {
    if (order.status_id && [ORDER_STATUS_ID_REQUESTED, PROCESSED_STATUS, SUPPLEMENT_STATUS, ADMITTED_STATUS, PREPARED_STATUS, SHIPPED_STATUS, DELIVERED_STATUS, ORDER_STATUS_ID_ON_HOLD, CANCELLED_STATUS, PARCIAL_RETURN_STATUS, PENALIZATION_STATUS, ORDER_STATUS_ID_PENDING_PAYMENT, REFUND_STATUS, RETURN_STATUS].includes(order.status_id)) {
      benefitsData.push({
        key: `order-${order.id}`,
        referred: true,
        name: order.user_id.name || '',
        status: t('users.referral_benefits.used_in_order'),
        benefit_amount: order.wallet_amount_used,
        created_at: order.created_at,
        referred_expired_at: null,
        tag_color: STATUS_TAG_COLORS.default,
        order_id: order.id
      })
    }
  })

  const sortedBenefits = benefitsData.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
  if (sortedBenefits[sortedBenefits.length - 1].referred) {
    sortedBenefits[sortedBenefits.length - 1].tag_color = STATUS_TAG_COLORS.accepted
    sortedBenefits[sortedBenefits.length - 1].status = t('users.referral_benefits.accepted')
  }

  return sortedBenefits
}
