import { Alert, Button, DateField, Icons, Modal, ShowButton, Table, Tag, Typography, useModal } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { User } from 'interfaces'
import { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, STATUS_TAG_COLORS, calculateReferralAndWalletAmounts, calculateReferralAndWalletAsync, currency } from 'utility'
import { QueryObserverResult } from '@tanstack/react-query'

type ModalProps = {
  user: User
  tableQueryResult: QueryObserverResult
}

type BenefitsData = {
  referred: boolean,
  key: string
  name: string
  status: string
  'referred_expired_at': string | null
  'benefit_amount': number
  'created_at': string
  'tag_color': string
  'order_id'?: number
}

export const ReferralBenefitsHistoryModal: React.FC<ModalProps> = ({ user, tableQueryResult }) => {
  const { modalProps, show } = useModal()
  const [benefits, setBenefits] = useState<BenefitsData[]>([])
  const [totalBenefits, setTotalBenefits] = useState(0)
  const [totalWalletAmountUsed, setTotalWalletAmountUsed] = useState(0)
  const [expiredBenefits, setExpiredBenefits] = useState(0)
  const t = useTranslate()

  const getData = async () => {
    const benefitsData = await calculateReferralAndWalletAsync(user)

    setBenefits(benefitsData)

    const { totalBenefits: _totalBenefits, expiredBenefits: _expiredBenefits } = calculateReferralAndWalletAmounts(benefitsData)

    const _totalWalletAmountUsed = benefitsData.filter(benefit => benefit.tag_color === STATUS_TAG_COLORS.default).reduce((acc, curr) => acc + curr.benefit_amount, 0)

    setTotalBenefits(_totalBenefits)
    setTotalWalletAmountUsed(_totalWalletAmountUsed)
    setExpiredBenefits(_expiredBenefits)
  }

  useEffect(() => {
    if (modalProps.visible) {
      tableQueryResult.refetch()
    }
  }, [modalProps.visible])

  useEffect(() => {
    if (modalProps.visible) {
      // const referralBenefits = user.referral_benefits
      // const orders = user.orders
      getData()
    }
  }, [modalProps.visible, user])

  return (
    <>
      <Button onClick={show} size="small" className="ant-btn ant-btn-primary">
        <Icons.UsergroupAddOutlined />
      </Button>

      <Modal
        {...modalProps}
        title={t('users.referral_benefits.title')}
        centered
        footer={null}
        style={{ minWidth: '50svw' }}
      >
        {totalWalletAmountUsed > totalBenefits && (
          <Alert
            message={t('common.warning')}
            description={t('users.referral_benefits.warning_wallet_amount_used_desc')}
            type="warning"
            showIcon
            icon={<Icons.WarningOutlined />}
            style={{ marginBottom: 20 }}
          />
        )}
        <Typography.Text style={{ display: 'block' }}>
          <Trans
            i18nKey="users.referral_benefits.wallet_balance"
            values={{ total: currency(user.wallet_balance) }}
            components={{ b: <b /> }}
          />
        </Typography.Text>
        <Typography.Text style={{ display: 'block' }}>
          <Trans
            i18nKey="users.referral_benefits.total_benefits"
            values={{ total: currency(totalBenefits) }}
            components={{ b: <b /> }}
          />
        </Typography.Text>
        <Typography.Text style={{ display: 'block' }}>
          <Trans
            i18nKey="users.referral_benefits.total_wallet_amount_used"
            values={{ total: currency(totalWalletAmountUsed) }}
            components={{ b: <b /> }}
          />
        </Typography.Text>
        <Typography.Text style={{ display: 'block' }}>
          <Trans
            i18nKey="users.referral_benefits.expired_benefits"
            values={{ total: currency(expiredBenefits) }}
            components={{ b: <b /> }}
          />
        </Typography.Text>

        <Table dataSource={benefits} rowKey={(record) => record.key} style={{ marginTop: 24 }}>
          <Table.Column
            key="name"
            dataIndex="name"
            title={t('users.name')}
          />
          <Table.Column
            key="status"
            dataIndex="status"
            title={t('users.referral_benefits.status')}
            align="center"
            render={(value, record: BenefitsData) => {
              return <Tag color={(record.referred_expired_at && record.referred_expired_at < new Date(Date.now()).toISOString()) ? 'default' : record.tag_color}>
                {(record.referred_expired_at && record.referred_expired_at < new Date(Date.now()).toISOString()) ? t('users.referral_benefits.expired') : value}
              </Tag>
            }}
          />
          <Table.Column
            key="benefit_amount"
            dataIndex="benefit_amount"
            title={t('users.referral_benefits.benefit_amount')}
            align="center"
            render={(value) => currency(value)}
          />
          <Table.Column
            key="created_at"
            dataIndex="created_at"
            title={t('users.referral_benefits.created_at')}
            align="center"
            render={(value) => <DateField value={value} format={DEFAULT_DATE_TIME_FORMAT} />}
          />
          <Table.Column
            key="referred_expired_at"
            dataIndex="referred_expired_at"
            title={t('users.referral_benefits.referred_expired_at')}
            align="center"
            render={(value) => (value !== null) ? <DateField value={value} format={DEFAULT_DATE_FORMAT} /> : ''}
          />
          <Table.Column
            key="order_id"
            dataIndex="order_id"
            title={t('users.referral_benefits.order')}
            align="center"
            render={(value) => value && (
              <ShowButton
                resourceNameOrRouteName='orders'
                recordItemId={value}
              >
                {`#${value}`}
              </ShowButton>
            )}
          />
        </Table>
      </Modal>
    </>
  )
}
