import { IResourceComponentsProps, useSelect as useSelectCore } from '@pankod/refine-core'
import {
  List,
  Table,
  Space,
  getDefaultSortOrder,
  FilterDropdownProps,
  Select,
  useModal,
  useEditableTable,
  Form,
  SaveButton,
  Button,
  DateField,
  useDrawerForm,
  CreateButton
} from '@pankod/refine-antd'

import { Order, Supplement, definitions, User, ReferralBenefits } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { ButtonLogicDelete } from 'components/DeleteModal/buttonLogicDelete'
import { DeleteModal } from 'components/DeleteModal/modal'
import { useState } from 'react'
import { DateFilterDropdown, SelectFilterDropdown } from 'components/filterDropdown'
import { ExportToExcelButton } from 'components/ExportToExcelButton/ExportToExcelButton'
import { ADMITTED_STATUS, BenefitsData, CANCELLED_STATUS, DECREASE_RETURN_STATUS, DEFAULT_DATE_TIME_FORMAT, DELIVERED_STATUS, INTERNAL_SPENDING_RETURN_STATUS, ORDER_STATUS_ID_ON_HOLD, ORDER_STATUS_ID_PENDING_PAYMENT, ORDER_STATUS_ID_REQUESTED, PARCIAL_RETURN_STATUS, PENALIZATION_STATUS, PREPARED_STATUS, PROCESSED_STATUS, REFUND_STATUS, RETURN_STATUS, SHIPPED_STATUS, STATUS_TAG_COLORS, SUPPLEMENT_STATUS, calculateReferralAndWalletAmounts, currency, formatDateTime, getAllUsers, getLastOrder } from 'utility'
import { ExportUsersWithoutOrdersButton } from 'components/ExportUsersWithoutOrdersButton/ExportUserWithoutOrdersButton'
import { CreateUserButton } from 'components/CreateUserButton/CreateUserButton'
import { getInternalShipmentTotal } from 'components/orders/user_addresses'
import { ReferralBenefitsHistoryModal } from 'components/ReferralBenefitsHistoryModal/ReferralBenefitsHistoryModal'
import { AddAmountToWalletModal } from 'components/AddAmountToWallet/AddAmountToWalletModal'

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    sorter,
    formProps,
    isEditing,
    setId: setEditId,
    saveButtonProps,
    cancelButtonProps,
    filters,
    setFilters,
    tableQueryResult
  } = useEditableTable<User>({
    permanentFilter: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ],
    metaData: {
      select: `id, email, name, role_id, created_at, last_access, wallet_balance, referred_by_user_id(referral_benefits!referrer_user_id(id, benefit_amount)),
        role(name),
        referrer_benefits:referral_benefits!referrer_user_id(id, order_id, status, benefit_amount, created_at, referred_expired_at, referrer_user_id(id, name), referred_user_id(id, name)),
        referred_benefits:referral_benefits!referred_user_id(id, order_id, status, benefit_amount, created_at, referred_expired_at, referrer_user_id(id, name), referred_user_id(id, name)),
        orders!user_id(id, status_id, wallet_amount_used, created_at, user_id(name))`
    },
    initialSorter: [
      {
        field: 'orders.id',
        order: 'desc'
      }
    ]
  })

  const { t } = useTranslation()
  const { modalProps, show, close } = useModal()
  const [id, setId] = useState(0)

  const { options: rolesOptions } = useSelectCore<definitions['roles']>({
    resource: 'roles',
    fetchSize: 30,
    optionLabel: 'name',
    optionValue: 'id',
    sort: [
      {
        field: 'id',
        order: 'asc'
      }
    ]
  })

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    show: createUserShow,
    close: createUserClose
  } = useDrawerForm<definitions['users']>({
    action: 'create',
    resource: 'users',
    redirect: false
  })

  // eslint-disable-next-line camelcase
  const prepareData = (user: User & { orders: Order[], referrer_benefits: ReferralBenefits[], referred_benefits: ReferralBenefits[] }) => {
    const benefitsData: BenefitsData[] = []
    if (user.referred_benefits) {
      user.referred_benefits.forEach((rb: any) => {
        benefitsData.push({
          key: `benefit-referred-${rb.id}`,
          referred: true,
          name: (rb.referrer_user_id.id !== 0) ? rb.referred_user_id.name || '' : rb.referrer_user_id.name || '',
          status: t(`users.referral_benefits.${rb.status}`),
          benefit_amount: rb.benefit_amount,
          created_at: rb.created_at,
          referred_expired_at: rb.referred_expired_at || null,
          tag_color: STATUS_TAG_COLORS[rb.status as keyof typeof STATUS_TAG_COLORS],
          order_id: rb.order_id
        })
      })
    }
    if (user.referrer_benefits) {
      user.referrer_benefits.forEach((rb: any) => {
        benefitsData.push({
          key: `benefit-referrer-${rb.id}-${rb.referred_user_id.name}`,
          referred: false,
          name: rb.referred_user_id.name || '',
          status: t(`users.referral_benefits.${rb.status}`),
          benefit_amount: rb.benefit_amount,
          created_at: rb.created_at,
          referred_expired_at: null,
          tag_color: STATUS_TAG_COLORS[rb.status as keyof typeof STATUS_TAG_COLORS]
        })
      })
    }
    if (user.orders) {
      user.orders.forEach((order) => {
        if (order.status_id && [ORDER_STATUS_ID_REQUESTED, PROCESSED_STATUS, SUPPLEMENT_STATUS, ADMITTED_STATUS, PREPARED_STATUS, SHIPPED_STATUS, DELIVERED_STATUS, ORDER_STATUS_ID_ON_HOLD, CANCELLED_STATUS, PARCIAL_RETURN_STATUS, PENALIZATION_STATUS, ORDER_STATUS_ID_PENDING_PAYMENT, REFUND_STATUS, RETURN_STATUS].includes(order.status_id)) {
          benefitsData.push({
            key: `order-${order.id}`,
            referred: true,
            name: order.user_id.name || '',
            status: t('users.referral_benefits.used_in_order'),
            benefit_amount: order.wallet_amount_used,
            created_at: order.created_at,
            referred_expired_at: null,
            tag_color: STATUS_TAG_COLORS.default,
            order_id: order.id
          })
        }
      })
    }

    const sortedBenefits = benefitsData.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    if (sortedBenefits[sortedBenefits.length - 1]?.referred) {
      sortedBenefits[sortedBenefits.length - 1].tag_color = STATUS_TAG_COLORS.accepted
      sortedBenefits[sortedBenefits.length - 1].status = t('users.referral_benefits.accepted')
    }

    const { totalBenefits: _totalBenefits, expiredBenefits: _expiredBenefits } = calculateReferralAndWalletAmounts(benefitsData)

    const _totalWalletAmountUsed = benefitsData.filter(benefit => benefit.tag_color === STATUS_TAG_COLORS.default)
      .reduce((acc, curr) => acc + curr.benefit_amount, 0)

    return { accumulatedTotal: _totalBenefits, totalWalletAmountUsed: _totalWalletAmountUsed, expiredBenefits: _expiredBenefits }
  }

  const dataToExport = (userList: any[]) => {
    return userList?.map((user: any) => {
      const internalShipmentTotal = getInternalShipmentTotal(user.orders)
      const supplementsTotal = user.orders?.filter((o: Order) => o.status_id && ![ORDER_STATUS_ID_PENDING_PAYMENT, CANCELLED_STATUS, RETURN_STATUS].includes(o.status_id)).map((o: Order) => o.supplements.filter((s: Supplement) => s.volumetric).reduce((acc, curr) => acc + (curr.supplement || 0), 0)).reduce((acc: any, curr: any) => acc + curr, 0)
      const insuranceTotal = user.orders?.filter((o: Order) => o.status_id && ![ORDER_STATUS_ID_PENDING_PAYMENT, CANCELLED_STATUS, RETURN_STATUS].includes(o.status_id)).reduce((acc: any, curr: any) => acc + (curr.shipping_insurance_price || 0), 0) || 0
      const customOrdersTotal = user.orders?.filter((o: Order) => o.status_id && ![ORDER_STATUS_ID_PENDING_PAYMENT, CANCELLED_STATUS, RETURN_STATUS].includes(o.status_id)).reduce((acc: any, curr: any) => acc + curr.custom_order_price, 0) || 0
      const internalTotal = Number(internalShipmentTotal) + Number(supplementsTotal) + Number(insuranceTotal) + Number(customOrdersTotal)

      const sortedOrders = user?.orders.sort((a: any, b: any) => b.id - a.id)

      const { accumulatedTotal, totalWalletAmountUsed, expiredBenefits } = prepareData(user)

      return {
        [t('users.id')]: user?.id,
        [t('users.email')]: user?.email,
        [t('users.name')]: user?.name || '',
        [t('users.address_full_name')]: `${user?.default_shipping_address_id?.name || ''} ${user?.default_shipping_address_id?.surnames || ''}`,
        [t('users.address_nif')]: user?.default_shipping_address_id?.nif || '',
        [t('users.created_at')]: formatDateTime(user?.created_at) || '',
        [t('users.role')]: user?.role?.name || 'Basic User',
        [t('users.orders')]: user?.orders?.filter((o: any) => o.status_id !== ORDER_STATUS_ID_PENDING_PAYMENT).length || 0,
        [t('users.ordersInProgress')]: user.orders?.filter((o: any) => o.status_id && [ORDER_STATUS_ID_REQUESTED, PROCESSED_STATUS, SUPPLEMENT_STATUS, ADMITTED_STATUS, PREPARED_STATUS, ORDER_STATUS_ID_ON_HOLD].includes(o.status_id)).length,
        [t('users.delivered_orders')]: user?.orders && user?.orders.length && user.orders?.filter((o: Order) => o.status_id === DELIVERED_STATUS).length | 0,
        [t('users.cancelled_orders')]: user?.orders && user?.orders.length && user.orders?.filter((o: Order) => o.status_id === CANCELLED_STATUS).length | 0,
        [t('users.totalReturns')]: user?.orders?.filter((o: any) => o.status_id === REFUND_STATUS).length || 0,
        [t('users.partialReturns')]: user?.orders?.filter((o: any) => o.status_id === PARCIAL_RETURN_STATUS).length || 0,
        [t('users.mermaOrders')]: user?.orders?.filter((o: any) => o.status_id === DECREASE_RETURN_STATUS).length || 0,
        [t('users.internalExpenses')]: user?.orders?.filter((o: any) => o.status_id === INTERNAL_SPENDING_RETURN_STATUS).length || 0,
        [t('users.refund_orders')]: user?.orders && user?.orders.length && user.orders?.filter((o: Order) => [REFUND_STATUS, PARCIAL_RETURN_STATUS].includes(o.status_id!)).length | 0,
        [t('users.penalization_orders')]: user?.orders && user?.orders.length && user.orders?.filter((o: Order) => o.status_id === PENALIZATION_STATUS).length | 0,
        [t('order.pendingPaymentOrders')]: user?.orders && user?.orders.length && user.orders?.filter((o: Order) => o.status_id === ORDER_STATUS_ID_PENDING_PAYMENT).length | 0,
        [t('users.total')]: currency(user?.orders?.filter((ord: any) => ord.status_id && ![ORDER_STATUS_ID_PENDING_PAYMENT, CANCELLED_STATUS, RETURN_STATUS].includes(ord.status_id)).reduce((acc: number, curr: definitions['orders']) => acc + Number(curr.total), 0)).replace('.', ','),
        [t('users.internal_shipment_total')]: currency(internalShipmentTotal).replace('.', ','),
        [t('users.supplements_total')]: currency(Number(supplementsTotal)).replace('.', ','),
        [t('users.insurance_total')]: currency(Number(insuranceTotal)).replace('.', ','),
        [t('users.custom_orders_total')]: currency(customOrdersTotal).replace('.', ','),
        [t('users.internal_total')]: currency(Number(internalTotal)).replace('.', ','),
        [t('users.wallet_balance')]: currency(Number(user?.wallet_balance)).replace('.', ','),
        [t('users.total_benefits')]: currency(accumulatedTotal) || currency(0).replace('.', ','),
        [t('users.total_wallet_amount_used')]: currency(totalWalletAmountUsed) || currency(0).replace('.', ','),
        [t('users.expired_benefits')]: currency(expiredBenefits) || currency(0).replace('.', ','),
        [t('users.last_order_date')]: sortedOrders && sortedOrders.length ? getLastOrder(sortedOrders, -1) : '',
        [t('users.prev_last_order_date')]: sortedOrders && sortedOrders.length ? getLastOrder(sortedOrders, -2) : ''
      }
    })
  }

  const exportEmails = async () => {
    const data = await getAllUsers()
    return dataToExport(data as any[])
  }

  return (
    <List
      headerProps={{
        // extra: <ExportToExcelButton filename='Emails' jsonData={dataToExport(tableProps.dataSource as any[])} />
        extra: <>
          <CreateButton onClick={() => createUserShow()}>{t('users.actions.addUser')}</CreateButton>
          <ExportUsersWithoutOrdersButton filename='Users_without_orders' />
          <ExportToExcelButton filename='Emails' asyncFunction={() => exportEmails()} />
        </>
      }}
    >
      <Form {...formProps}>
        <Table
          {...tableProps}
          rowKey="id"
          rowClassName={(record) => {
            const { totalWalletAmountUsed, accumulatedTotal } = prepareData(record as any)

            if (totalWalletAmountUsed > accumulatedTotal) {
              return 'user-warning'
            } else {
              return 'user-normal'
            }
          }}
          onRow={(record) => ({
            onClick: (event: any) => {
              if (event.target.classList.contains('role-id') || event.target.closest('.role-id')) {
                setEditId && setEditId(record.id)
              }
            }
          })}
        >
          <Table.Column
            key="id"
            dataIndex="id"
            title="ID"
            sorter
            defaultSortOrder={getDefaultSortOrder('id', sorter)}
          />
          <Table.Column<definitions['users']>
            key="id"
            dataIndex="name"
            title={t('users.name')}
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <SelectFilterDropdown
                props={props}
                entity='users'
                optionLabel='name'
                optionValue='id'
                filters={[{
                  field: 'deleted',
                  operator: 'eq',
                  value: false
                }]}
              />
            )}
          />
          <Table.Column<definitions['users']>
            key="address-full-name"
            dataIndex="default_shipping_address_id"
            title={t('users.address_full_name')}
            sorter
            render={(value) => `${value?.name || ''} ${value?.surnames || ''}`}
          />
          <Table.Column<definitions['users']>
            key="address-nif"
            dataIndex="default_shipping_address_id"
            title={t('users.address_nif')}
            sorter
            render={(value) => value?.nif || ''}
          />
          <Table.Column<definitions['users']>
            key="id"
            dataIndex="email"
            title={t('users.email')}
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <SelectFilterDropdown
                props={props}
                entity='users'
                optionLabel='email'
                optionValue='id'
                filters={[{
                  field: 'deleted',
                  operator: 'eq',
                  value: false
                }]}
              />
            )}
          />
          <Table.Column<definitions['users']>
            key='created_at'
            dataIndex="created_at"
            align="center"
            title={t('users.created_at')}
            render={(value) => <DateField format={DEFAULT_DATE_TIME_FORMAT} value={value}/>}
            sorter
            filterDropdown={() => (
              <DateFilterDropdown
                field="created_at"
                filters={filters}
                setFilters={setFilters}
              />
            )}
          />
          <Table.Column<definitions['users']>
            key='lastAccess'
            dataIndex="last_access"
            align="center"
            title={t('users.lastAccess')}
            render={(value: any) => (value) ? <DateField format={DEFAULT_DATE_TIME_FORMAT} value={value} /> : null}
          />
          <Table.Column<definitions['users']>
            dataIndex='role_id'
            key="role_id"
            title={t('users.role')}
            className='role-id'
            render={(value, data: definitions['users']) => {
              if (isEditing(data.id)) {
                return (
                  <Form.Item
                    name='role_id'
                    style={{ margin: 0 }}
                  >
                    <Select options={rolesOptions} />
                  </Form.Item>
                )
              }
              return rolesOptions.filter(role => role.value === value)[0]?.label
            }}
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <SelectFilterDropdown
                props={props}
                entity='roles'
                optionLabel='name'
                optionValue='id'
                sorters={[{
                  field: 'id',
                  order: 'asc'
                }]}
              />
            )}
          />
          <Table.Column<definitions['users']>
            key='users.count'
            dataIndex="orders"
            align="center"
            title={t('users.orders')}
            render={(value: definitions['orders'][]) => value.filter((ord) => ord.status_id && ![ORDER_STATUS_ID_PENDING_PAYMENT, CANCELLED_STATUS, RETURN_STATUS].includes(ord.status_id)).length}
          />
          <Table.Column<definitions['users']>
            key='orders-total'
            dataIndex="orders"
            align="center"
            title={t('users.total')}
            render={(value: definitions['orders'][]) => currency(value.filter((ord) => ord.status_id && ![ORDER_STATUS_ID_PENDING_PAYMENT, CANCELLED_STATUS, RETURN_STATUS].includes(ord.status_id)).reduce((acc, curr) => acc + Number(curr.total), 0))}
          />
          <Table.Column<definitions['users']>
            key='wallet_balance'
            dataIndex="wallet_balance"
            align="center"
            title={t('users.wallet_balance')}
            render={(value: number) => currency(value)}
          />
          <Table.Column<definitions['users']>
            key='accumulatedTotal'
            // dataIndex="accumulatedTotal"
            align="center"
            title={t('users.total_benefits')}
            render={(_value: any, record: any) => currency(prepareData(record).accumulatedTotal)}
          />
          <Table.Column<definitions['users']>
            key='totalWalletAmountUsed'
            // dataIndex="accumulatedTotal"
            align="center"
            title={t('users.total_wallet_amount_used')}
            render={(_value: any, record: any) => currency(prepareData(record).totalWalletAmountUsed)}
          />
          <Table.Column<definitions['users']>
            key='expiredBenefits'
            // dataIndex="accumulatedTotal"
            align="center"
            title={t('users.expired_benefits')}
            render={(_value: any, record: any) => currency(prepareData(record).expiredBenefits)}
          />
          <Table.Column<definitions['users']>
            key='last-order-date'
            dataIndex="orders"
            align="center"
            title={t('users.last_order_date')}
            render={(value: definitions['orders'][]) => value && value.length ? getLastOrder(value as any, -2) : null}
          />
          <Table.Column<definitions['users']>
            key='prev-last-order-date'
            dataIndex="orders"
            align="center"
            title={t('users.prev_last_order_date')}
            render={(value: definitions['orders'][]) => value && value.length ? getLastOrder(value as any, -2) : null}
          />
          <Table.Column<User>
            title={t('table.actions')}
            dataIndex="actions"
            render={(_, record) => {
              if (isEditing(record.id)) {
                return (
                  <Space>
                    <SaveButton
                      {...saveButtonProps}
                      hideText
                      size="small"
                    />
                    <Button
                      {...cancelButtonProps}
                      size="small"
                    >
                      {t('buttons.cancel')}
                    </Button>
                  </Space>
                )
              }

              return (
                <Space>
                  <ButtonLogicDelete click={() => {
                    show()
                    setId(record.id)
                  }} />
                  <DeleteModal
                    modalProps={modalProps}
                    close={close}
                    id={id}
                    entity='users'
                    tableQueryResult={tableQueryResult}
                  />
                  <AddAmountToWalletModal user={record}/>
                  <ReferralBenefitsHistoryModal user={record} tableQueryResult={tableQueryResult} />
                </Space>
              )
            }}
          />
        </Table>
        <CreateUserButton
          drawerProps={createDrawerProps}
          formProps={createFormProps}
          closeDrawer={createUserClose}
          tableQueryResult={tableQueryResult}
        />
      </Form>
    </List>
  )
}
